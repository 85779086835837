import {useState} from "react";
import {toast} from "react-toastify";
import {BallTriangle} from "react-loader-spinner";


function App() {
    const [isLoading, setIsLoading] = useState(false)
    const [right, setRight] = useState([])
    const [wrong, setWrong] = useState([])
    const [emails, setEmails] = useState('')

    const API_LINK = "https://demo.tmdynamicllc.com/api/emailVerifier";
    const submitButton = async () => {
        try {
            if (emails.length === 0) {
                toast.warn("Emails can't be empty.")
            } else {
                setIsLoading(true);
                const email = emails.split('\n');
                let toaster = toast.loading("Processing...");
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    "emailData": email
                });
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                await fetch(`${API_LINK}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        // console.log(result)
                        if (result.status === 1) {
                            setRight(result.right)
                            setWrong(result.wrong)
                            toast.update(toaster, {
                                type: "success",
                                render: "The provided emails are filtered successfully.",
                                isLoading: false,
                                autoClose: toaster,
                                pauseOnHover: false,
                                pauseOnFocusLoss: false,
                                closeButton: true,
                                closeOnClick: true,
                            })
                        } else {
                            toast.dismiss(toaster.current);
                            toast.error("Server Error! Please try again later");
                        }
                    })
                    .catch(error => console.log('error', error));
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <div className="container">
            {
                isLoading &&
                <div className="loading-container">
                    <BallTriangle
                        height={100}
                        width={100}
                        radius={5}
                        color="#000"
                        ariaLabel="ball-triangle-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            }
            <div className="section">
                <h1>Email Verifier</h1>
                <div className="section-data">
                    <textarea name="emails" id="emails" placeholder={'List of Emails'} value={emails}
                              onChange={(event) => setEmails(event.target.value)}></textarea>
                    <button onClick={submitButton}>Filter Now</button>
                </div>
                <div className="section-result">
                    <div className="right">
                        {
                            right.length > 0 &&
                            right.map((item, index) => (
                                <div key={index} className="reuslt-item">{item}</div>
                            ))
                        }
                    </div>
                    <div className="wrong">
                        {
                            wrong.length > 0 &&
                            wrong.map((item, index) => (
                                <div key={index} className="reuslt-item">{item}</div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
